<template>
    <div>
        <div class="d-flex justify-space-between align-center">
            <div style="width: 100px">
                <v-autocomplete
                        v-model="lang"
                        :rules="[() => !!lang || 'This field is required']"
                        :items="languages"
                        label="Lang"
                        required
                        solo
                        hide-details
                        dense
                />
            </div>
            <v-btn
                    color="primary"
                    elevation="2"
                    @click="openForm(null, createNewsObject)"
            >Add news</v-btn>
        </div>

        <v-card
                class="mx-auto my-4"
                v-for="news in getNews"
                :key="news.id"
        >

            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            News
                        </th>
                        <th class="text-right">
                            <template>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="openForm(news, createNewsObject)"
                                               v-on="on"
                                               v-bind="attrs"
                                               icon color="info">
                                            <v-icon small>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="deleteItem(news)"
                                               v-on="on"
                                               v-bind="attrs"
                                               icon color="red">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </template>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="font-weight-medium">Title</td>
                        <td>{{ lang === 'uz' ? news.titleUz : lang === 'ru' ? news.titleRu : lang === 'en' ? news.titleEn : ''}}</td>
                    </tr>
                    <tr>
                        <td class="font-weight-medium">Content</td>
                        <td v-html="lang === 'uz' ? news.contentUz : lang === 'ru' ? news.contentRu : lang === 'en' ? news.contentEn : ''"></td>
                    </tr>
                    <tr>
                        <td class="font-weight-medium">Img</td>
                        <td>
                            <div class="service__img">
                                <img :src="baseURL + news.img"/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>

        <Modal v-model="modal"
               width="1140px"
               title="News form">
            <Form v-if="modal"
                  :data-object="dataObject"
                  @close-modal="modal = false"
                  @send-news="sendNews"
            />
        </Modal>
    </div>
</template>

<script>
    import Form from "./Form";
    import formModal from "@/mixins/formModal";
    import deleteModal from "@/mixins/deleteModal";
    import { baseURL } from "@/services/api";
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "List",
        data: () => ({
            baseURL,
            lang: 'uz',
            languages: ['uz', 'ru', 'en'],
        }),
        components: { Form },
        computed: {
            ...mapGetters({
                getNews: 'getNews',
            })
        },
        mixins: [formModal, deleteModal],
        methods: {
            ...mapActions({
                fetchNews: 'fetchNews',
                createNews: 'createNews',
                updateNews: 'updateNews',
                deleteNews: 'deleteNews',
            }),

            createNewsObject (news = null) {
                const object = {
                    titleUz: news ? news.titleUz : '',
                    titleRu: news ? news.titleRu : '',
                    titleEn: news ? news.titleEn : '',
                    contentUz: news ? news.contentUz : '',
                    contentRu: news ? news.contentRu : '',
                    contentEn: news ? news.contentEn : '',
                };
                if (news) object.id = news.id;
                else object.img = null;

                return object;
            },

            async sendNews(data) {
                if (this.dataObject.id) {
                    await this.updateNews(data);
                } else {
                    await this.createNews(data);
                }

                await this.fetchNews();
                this.modal = false;
            },

            async deleteItem(news) {
                await this.deleteNews(news.id);
                await this.fetchNews();
            }
        },
        async mounted() {
            await this.fetchNews();
        }
    }
</script>

<style scoped lang="scss">
    .service__img {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        overflow: hidden;

        & img {
            height: 100px;
        }
    }
</style>
