<template>
    <div>
        <v-form ref="form"
                @submit.prevent="submit">
            <v-text-field v-model="dataObject.titleUz"
                          label="titleUz"
                          clearable/>
            <v-text-field v-model="dataObject.titleRu"
                          label="titleRu"
                          clearable/>
            <v-text-field v-model="dataObject.titleEn"
                          label="titleEn"
                          clearable/>

            <p>Content UZ</p>
            <ckeditor
                    v-model="dataObject.contentUz"
                    :editor="editor"
                    :config="editorConfig" />

            <p>Content RU</p>
            <ckeditor
                    v-model="dataObject.contentRu"
                    :editor="editor"
                    :config="editorConfig" />

            <p>Content EN</p>
            <ckeditor
                    v-model="dataObject.contentEn"
                    :editor="editor"
                    :config="editorConfig" />

            <v-file-input v-model="dataObject.img"
                          accept="image/*"
                          label="bigImg"
            />
            <v-row no-gutters>
                <v-spacer/>
                <v-btn @click="closeModal" color="primary" text class="mr-3">Cancel</v-btn>
                <v-btn type="submit" color="primary" depressed>Save</v-btn>
            </v-row>
        </v-form>
    </div>
</template>

<script>
    import {baseURL} from "@/services/api";
    import {createFormData} from "@/services/validationRules";

    import Editor from 'ckeditor5-custom-build/build/ckeditor';
    import TokenService from "@/services/token";
    export default {
        props: {
            dataObject: Object
        },
        name: "Form",
        data: () => ({
            editor: Editor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: baseURL + "image/upload",
                    withCredentials: false,
                    headers: {
                        Authorization: `${TokenService.getToken()}`
                    }
                }
            }
        }),
        methods: {
            submit() {
                this.$emit('send-news', createFormData(this.dataObject))
            },
            closeModal() {
                this.$emit('close-modal');
            }
        },
    }
</script>

<style scoped>

</style>